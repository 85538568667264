<template>
  <div class="card border-0 bg-light shadow-sm day-card">
    <div class="card-header">
      <h6 class="fw-bold mb-0">{{ day.title }}</h6>
      <small>{{ day.date | formatDate }}</small>
    </div>
    <div class="card-body" style="overflow: scroll">
      <loading v-if="busy"></loading>
      <ul
        class="list-group list-group-flush"
        v-if="!busy && arrivals.length > 0"
      >
        <li class="list-group-item" v-for="b in arrivals" :key="b.id">
          <h6 class="fw-bold mb-0">
            Booking #{{ b.id }}
            <small class="fw-normal text-capitalize mb-0"
              >/ {{ b.room_config }}</small
            >
          </h6>
          <div>
            <ul class="list-unstyled my-2">
              <li
                v-for="c in b.clients"
                :key="c.id"
                class="list-group-item cursor-pointer"
                @click="
                  $router.push(
                    '/clients/' +
                      c.id +
                      '?retreat-booking=' +
                      b.booking.id +
                      '#retreat-bookings'
                  )
                "
              >
                <div class="mb-0">
                  <small class="mb-0 d-block">{{ c.name }}</small>

                  <small class="mb-0">{{
                    c.phone ? c.phone : c.mobile ? c.mobile : "no number"
                  }}</small>
                </div>
                <ul class="mt-0 pt-0">
                  <li class="text-capitalize" v-if="type == 'due-ins'">
                    <small>
                      HQ:
                      {{
                        formToFillIn(b, c)
                          ? formToFillIn(b, c).status.replace(/_/g, " ")
                          : "Unkown"
                      }}</small
                    >
                  </li>

                  <!--  -->

                  <li
                    class="text-capitalize"
                    v-if="type == 'housekeeping' || type == 'due-ins'"
                  >
                    <small> Room: {{ b.room.name }}</small>
                  </li>

                  <li class="text-capitalize" v-if="type == 'housekeeping'">
                    <small> Floor: {{ b.room.floor }}</small>
                  </li>

                  <li class="text-capitalize" v-if="type == 'housekeeping'">
                    <small> Config: {{ b.room_config }}</small>
                  </li>

                  <li
                    class="text-capitalize"
                    v-if="type == 'housekeeping' || type == 'due-ins'"
                  >
                    <small> Arrival: {{ b.arrival_date | formatDate }}</small>
                  </li>

                  <li
                    class="text-capitalize"
                    v-if="type == 'housekeeping' || type == 'due-ins'"
                  >
                    <small> Nights: {{ b.booking.total_nights }}</small>
                  </li>

                  <li
                    class="text-capitalize"
                    v-if="type == 'housekeeping' || type == 'due-ins'"
                  >
                    <small>
                      Departure: {{ b.departure_date | formatDate }}</small
                    >
                  </li>
                </ul>
                <p
                  class="bg-light w-100 p-2 my-2"
                  v-if="type == 'housekeeping' || type == 'due-ins'"
                >
                  <small>
                    Allergies: {{ hasAllergies(b.booking.forms, c) }}</small
                  >
                </p>
                <p class="bg-light w-100 p-2 my-2" v-if="type == 'due-ins'">
                  <small
                    >Intolerances: {{ intolerances(b.booking.forms, c) }}</small
                  >
                </p>

                <p class="bg-light w-100 p-2 my-2" v-if="type == 'due-ins'">
                  <small>Reasons: {{ reasons(b.booking.forms, c) }}</small>
                </p>
              </li>
            </ul>
          </div>

          <div>
            <small>Departure: {{ b.departure_date | formatDate }}</small>
          </div>
        </li>
      </ul>

      <!--  -->
      <div class="row" v-if="!busy && arrivals.length == 0">
        <div class="col">
          <p class="mb-0">No Arrivals</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/loaders/Loading";

export default {
  props: ["day", "retreat", "type"],
  data() {
    return {
      busy: false,
      arrivals: []
    };
  },
  watch: {
    day: {
      handler: function(val, old) {
        this.fetchArrivals();
      }
    }
  },
  methods: {
    hasAllergies(forms, client) {
      var hasAllergy = "No allergies";
      var form = forms.find(f => f.client_id == client.id);
      if (form) {
        var res = JSON.parse(form.sections[3].client_response);
        if (res[14].userData) {
          if (res[14].userData[0]) {
            hasAllergy = res[14].userData[0];
            if (
              hasAllergy == "null" ||
              hasAllergy == "n/a" ||
              hasAllergy == "N/A"
            ) {
              hasAllergy = "No allergies";
            }
          }
        }
      }

      return hasAllergy;
    },
    goals(forms, client) {
      var goal = "N/A";
      var form = forms.find(f => f.client_id == client.id);
      if (form) {
        var res = JSON.parse(form.sections[1].client_response);

        if (res[6].userData) {
          if (res[6].userData[0]) {
            goal = res[6].userData[0];
            if (goal == "null" || goal == "n/a" || goal == "N/A") {
              goal = "N/A";
            }
          }
        }
      }

      return goal;
    },

    intolerances(forms, client) {
      var goal = "N/A";
      var form = forms.find(f => f.client_id == client.id);
      if (form) {
        var res = JSON.parse(form.sections[3].client_response);
        console.log(res);
        if (res[17].userData) {
          if (res[17].userData[0]) {
            goal = res[17].userData[0];
            if (goal == "null" || goal == "n/a" || goal == "N/A") {
              goal = "N/A";
            }
          }
        }
      }

      return goal;
    },

    reasons(forms, client) {
      var reason = "N/A";
      var form = forms.find(f => f.client_id == client.id);
      if (form) {
        var res = JSON.parse(form.sections[1].client_response);

        if (res[7].userData) {
          if (res[7].userData[0]) {
            reason = res[7].userData[0];
            if (reason == "null" || reason == "n/a" || reason == "N/A") {
              reason = "N/A";
            }
          }
        }
      }

      return reason;
    },
    formToFillIn(b, client) {
      return b.booking.forms.find(x => x.client_id == client.id);
    },
    fetchArrivals() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/bookings/" + this.type, {
          date: moment(this.day.date),
          retreat: this.retreat
        })
        .then(({ data }) => {
          this.arrivals = data;
          this.busy = false;
        });
    }
  },
  mounted() {
    this.fetchArrivals();
  },
  filters: {
    formatDate: function(date) {
      return moment(date).format("L");
    }
  },
  components: {
    Loading
  }
};
</script>

<style>
</style>