<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="text-center">
      <div
        class="spinner-border spinner-border-lg text-success"
        :style="heightWidth"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <p class="text-2xl font-semibold">
        <slot></slot>
      </p>

      <!-- <img src="../../../assets/images/minion.gif" alt="" class="mx-auto" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "40px",
    },
    width: {
      type: String,
      default: "40px",
    },
    color: {
      type: String,
      default: "text-primary",
    },
  },
  computed: {
    heightWidth() {
      return "height: " + this.height + "; width: " + this.width;
    },
    styles() {
      return this.heightWidth + " " + this.color;
    },
  },
};
</script>

<style>
.animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
